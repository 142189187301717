import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Spoiler } from "../../components/Spoiler";
export const _frontmatter = {
  "title": "Redux Selectors are Applicative Functors",
  "subtitle": "What that means and why it matters.",
  "date": "2019-03-11T00:00:00.000Z",
  "layout": "post",
  "draft": false,
  "tags": ["javascript", "redux", "functor", "applicative", "functional programming"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Redux selector functions are applicative functors. Why does this matter? If we can express our selectors as an applicative functor in a standardized way (wrt the `}<a parentName="p" {...{
        "href": "https://github.com/rpominov/static-land"
      }}>{`static-land`}</a>{` standard), we can leverage a whole suite of reusable code for free (from the `}<a parentName="p" {...{
        "href": "https://github.com/gcanti/fp-ts"
      }}>{`fp-ts`}</a>{` library), replacing some of the specialized code from reselect!`}</p>
    <p>{`Prerequisites: `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/docs/handbook/typescript-in-5-minutes.html"
      }}><em parentName="a"><strong parentName="em">{`typescript`}</strong></em></a>{`, `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/docs/handbook/generics.html"
      }}><em parentName="a"><strong parentName="em">{`generics`}</strong></em></a>{`, and `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Higher-order_function#JavaScript"
      }}><em parentName="a"><strong parentName="em">{`higher order functions`}</strong></em></a>{`.`}</p>
    <h2>{`The shape of a selector`}</h2>
    <p>{`Redux selectors are functions from state to some other value. This can be expressed in a type like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`state`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` State`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p>{`Where `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`State`}</code>{` is the type of our app state and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{` is the type of value we're selecting out of the state. So, for example, a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector<AppState, User>`}</code>{` would represent a function that takes `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`AppState`}</code>{` as it's parameter, and returns a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`User`}</code>{`. A `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector<AppState, Post[]>`}</code>{` represents a function that takes in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`AppState`}</code>{` and returns a list of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Post`}</code>{`s.`}</p>
    <p>{`For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` AppState `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` currentUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` User`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` posts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Post`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` selectCurrentUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`AppState`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` User`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`state`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`currentUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// selectCurrentUser returns a 'User'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` currentUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` User `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`selectCurrentUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`currentAppState`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` selectPosts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`AppState`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Post`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`state`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`posts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// selectPosts returns an array of Posts`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` posts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Post`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`selectPosts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`currentAppState`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p>{`We can think of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector<AppState, A>`}</code>{` as a kind of "wrapper" around the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{` value, where in order to get that value, we need to pass in an object with type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`AppState`}</code>{`.`}</p>
    <h2>{`Selectors are Functors`}</h2>
    <p>{`All functors are `}<em parentName="p">{`generic`}</em>{` (which means they have a type parameter), and have a function called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{` which takes a function that takes that generic value and returns a different value, thus changing or "mapping" the type of the generic value.`}</p>
    <p>{`So, in order for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector<State, A>`}</code>{` to be a functor, it must have a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{` method which takes a function from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{` to something else (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`B`}</code>{`), and returns a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector<State, B>`}</code>{`. We can define this function as a free-standing static function, instead of defining it on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector`}</code>{` type itself; this just means we'll invoke it like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map(selectPosts, posts => ...)`}</code>{` instead of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`selectPosts.map(posts => ...)`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` map`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  selector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`mapper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`This function takes a selector (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`State => A`}</code>{`) and a function from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A => B`}</code>{` (a to b), and returns a selector (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`State => B`}</code>{`). This function must work for any type of state, any type of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{` and any type of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`B`}</code>{`. Take some time to try and implement this function (You'll likely learn something in the process)! If you get stuck, come back and look at an example usage for some inspiration. `}<em parentName="p">{`Hint: start with what you have to return, and work backwards from there.`}</em></p>
    <Spoiler label="Click to see map's implementation" mdxType="Spoiler">
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "typescript"
      }}>{`
      `}<pre parentName="div" {...{
          "className": "language-typescript"
        }}><code parentName="pre" {...{
            "className": "language-typescript"
          }}>{`map`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`State`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`aSelector`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`State`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`ab`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`a`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token constant"
              }}>{`A`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`state`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` State`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// extract 'a' from state`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` a `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`aSelector`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`state`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// apply the callback to a`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` b `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`ab`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`a`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// now we have a 'b'!`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` b`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span></code></pre>{`
      `}</div>
    </Spoiler>
    <p>{`The key is to remember that mapping over a selector just applies the passed callback on the result of the selector.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Side track:`}<br parentName="p"></br>{`
`}{`Why did we define `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`map`}</code>{` as a plain static method? why not have a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Selector`}</code>{` class where each instance has a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`.map`}</code>{` method?`}</p>
      <p parentName="blockquote">{`Functionally, there is no difference between:`}</p>
      <div parentName="blockquote" {...{
        "className": "gatsby-highlight",
        "data-language": "typescript"
      }}>{`
      `}<pre parentName="div" {...{
          "className": "language-typescript"
        }}><code parentName="pre" {...{
            "className": "language-typescript"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`map`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`postsSelector`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token parameter"
            }}>{`posts`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` posts`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`length`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre>{`
      `}</div>
      <p parentName="blockquote">{`and`}</p>
      <div parentName="blockquote" {...{
        "className": "gatsby-highlight",
        "data-language": "typescript"
      }}>{`
      `}<pre parentName="div" {...{
          "className": "language-typescript"
        }}><code parentName="pre" {...{
            "className": "language-typescript"
          }}>{`postsSelector`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`map`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`posts`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` posts`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`length`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre>{`
      `}</div>
      <p parentName="blockquote">{`Both of these are viable ways of expressing a 'functor'. In fact, the second example is how `}<strong parentName="p">{`fantasy-land`}</strong>{` specifies how a functor should behave.`}</p>
      <p parentName="blockquote"><strong parentName="p">{`static-land`}</strong>{` is an alternative standard to fantasy-land which prefers the first form. One benefit to this approach is that you can define functors for types that you don't own.`}</p>
    </blockquote>
    <p>{`Where would this `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{` function be useful in practice? Consider if we had a selector that selects the recent posts out of the app state:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` postSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`AppState`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Posts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`state`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`posts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p>{`Suppose that we wanted a new selector, which only selected the count of all posts. We `}<em parentName="p">{`could`}</em>{` create a new selector:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` postCountSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`state`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`posts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p>{`This would duplicate the logic for getting the posts out of state (a trivial example, for simplicity). Instead, we could reuse the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`postsSelector`}</code>{`, and map over the result of that selector, returning just the size of the posts array:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` postCountSelector `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`postsSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`posts`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` posts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p>{`See the second parameter to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{`? That's the callback function that takes the result of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`postsSelector`}</code>{` and returns something else, thus 'mapping' the value inside. We had a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector<..., Post[]>`}</code>{` and we mapped that into a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector<.., number>`}</code>{`.`}</p>
    <h2>{`Selectors are Applicatives`}</h2>
    <blockquote>
      <p parentName="blockquote">{`The term 'applicative functor' is often shorthanded to 'applicative.' Both mean the same thing.`}</p>
      <p parentName="blockquote">{`All applicatives are also functors.`}</p>
    </blockquote>
    <p>{`An Applicative has a method called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ap`}</code>{` which takes a wrapped value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{` and a `}<em parentName="p">{`wrapped`}</em>{` function `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A => B`}</code>{`, and returns a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`B`}</code>{`. It's very similar to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{`, except that the callback function you pass is `}<em parentName="p">{`itself`}</em>{` wrapped in the wrapper type. For our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector`}</code>{` type, this looks like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` ap`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  aSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  abSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Think on this for a little bit: It takes two wrappers, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector<..., A>`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector<..., A => B>`}</code>{` but yet only returns one `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector<..., B>`}</code>{`. So this `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ap`}</code>{` function must include a way to `}<em parentName="p">{`compose`}</em>{` selectors! With that information, try to implement this `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ap`}</code>{` function which combines two selectors. Remember, start with the return type and work backwards from there.`}</p>
    <Spoiler label="Click to reveal solution" mdxType="Spoiler">
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "typescript"
      }}>{`
      `}<pre parentName="div" {...{
          "className": "language-typescript"
        }}><code parentName="pre" {...{
            "className": "language-typescript"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`function`}</span>{` ap`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`State`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`
  abSelector`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`State`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`a`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token constant"
              }}>{`A`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  aSelector`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`State`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`State`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`state`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` State`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// select a function "a => b" from state`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` ab `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`abSelector`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`state`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// select an "a" from state`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` a `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`aSelector`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`state`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// call the selected "a => b" function, passing it "a"`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`ab`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`a`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre>{`
      `}</div>
    </Spoiler>
    <p>{`How are applicatives useful? Using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ap`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{` methods, we can derive another method that maps over two wrappers of anything simultaneously, For example, given a wrapper type (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Wrapper`}</code>{`), we can make a method `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`combine2`}</code>{` which takes two wrappers, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Wrapper<A>`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Wrapper<B>`}</code>{`, and `}<em parentName="p">{`composes`}</em>{` them into a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Wrapper<[A, B]>`}</code>{` ("Wrapper" here, just refers to any applicative type, it could be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Option`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`List`}</code>{`, or even our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector`}</code>{`).`}</p>
    <p>{`For selectors, this would look like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` combine2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  aSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  bSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <blockquote>
      <p parentName="blockquote">{`Hard:`}<br parentName="p"></br>{`
`}{`Try to implement `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`combine2`}</code>{` using only our `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`ap`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`map`}</code>{` functions we built above.`}</p>
    </blockquote>
    <Spoiler label="Click to reveal solution" mdxType="Spoiler">
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "typescript"
      }}>{`
      `}<pre parentName="div" {...{
          "className": "language-typescript"
        }}><code parentName="pre" {...{
            "className": "language-typescript"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`function`}</span>{` combine2`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`State`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`
  aSelector`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`State`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  bSelector`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`State`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`State`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// take our "Selector<., B>" and map it into "Selector<., A => [A, B]>"`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` aToAbSelector`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`State`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`a`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token constant"
              }}>{`A`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`map`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`
    bSelector`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token parameter"
            }}>{`b`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`a`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token constant"
              }}>{`A`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`a`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` b`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// use ap to apply "Selector<., A => [A, B]>" to "Selector<., A>"`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// which gives us a "Selector<., [A, B]>"`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`ap`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`aSelector`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` aToAbSelector`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre>{`
      `}</div>
    </Spoiler>
    <p>{`This same strategy can be used to implement `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`combine3`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`combine4`}</code>{`, etc, all the way to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`combineN`}</code>{`. The fp-ts library provides this `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`combineN`}</code>{` function for us for free (we just need to implement `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ap`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{`)! This function is called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceT`}</code>{`, and you'd use it like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` sequenceT `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"fp-ts/lib/Apply"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` abcSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`C`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sequenceT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`aSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` bSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` cSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`aSelector`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bSelector`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cSelector`}</code>{` are all `}<em parentName="p">{`composed`}</em>{` into `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`abcSelector`}</code>{` (I'll explain the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`...`}</code>{` further down)!`}</p>
    <p>{`It turns out this pattern is incredibly powerful, and `}<strong parentName="p">{`you have undoubtedly already used this without knowing it.`}</strong>{` Consider `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Promise.all`}</code>{`; what does it's signature look like? It takes a unbound list of promises, and returns promise of a list, thus "`}<em parentName="p">{`composing`}</em>{`" all the promises in the list:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` abcPromise `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Promise`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`all`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`aPromise`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` bPromise`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` cPromise`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p>{`can you see the resemblance to our selector example?`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` abcSelector `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sequenceT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`aSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` bSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` cSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`The main difference is that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Promise.all`}</code>{` is specialized for promises, but `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceT`}</code>{` works for `}<em parentName="p">{`all`}</em>{` applicatives!`}</p>
    <h2>{`Typeclasses`}</h2>
    <p>{`In fp-ts, a type is considered an applicative if a static instance of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Applicative`}</code>{` interface exists for that type. Instead of having a class implement an interface, fp-ts makes you implement all of the methods on a separate object. For our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector`}</code>{` type we need `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ap`}</code>{`, and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`of`}</code>{` (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`of`}</code>{` just takes a static value and puts it into our wrapper type).`}</p>
    <p>{`Our selector typeclass would look like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`s`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` State`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`URI`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Selector"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` selector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Applicative2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`URI`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`URI`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`of`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  map`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`aSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`ab`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`state`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` State`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`aSelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` b `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ab`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  ap`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    abSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    aSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`state`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` State`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` ab `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`abSelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`aSelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ab`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`selector`}</code>{` constant is an instance of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Applicative`}</code>{` typeclass, but it's completely divorced from our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector`}</code>{` type itself; this makes it a "typeclass." While this is a bit more clumsy/verbose than the dot notation you're probably used to (i.e. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`selector.map()`}</code>{`), it has one major benefit; you can define a typeclass for `}<em parentName="p">{`any`}</em>{` type, even types that come from a completely different package, including the standard library types (looking at you, promises)! This means that if a library doesn't specify the functor or applicative behavior for their type, you can simply define it on a typeclass that you make yourself, instead of having to wrap the type in another class that you make.`}</p>
    <p>{`Since we have access to this `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`selector`}</code>{` typeclass, this means we can consider our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector`}</code>{` class as an applicative. Whenever a function needs to work for any applicative they simply take an instance of an applicative typeclass. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceT`}</code>{` is an example of such a function, and so if we want to use it with our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector`}</code>{` type, we just supply the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`selector`}</code>{` typeclass value (filling in the "..." from before):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` abcSelector `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sequenceT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`selector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`aSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` bSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` cSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p>{`This works for `}<em parentName="p">{`any`}</em>{` applicative; consider the need to compose many optional values into one optional value. We can just use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`option`}</code>{` typeclass provided by fp-ts:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` option `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"fp-ts/lib/Option"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` abcOption `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sequenceT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`option`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`aOption`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` bOption`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` cOption`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <h2>{`createSelector from reselect is just map + sequenceT`}</h2>
    <p>{`Consider the shape of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`createSelector`}</code>{` method that reselect provides:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`createSelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  aSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  bSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  cSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` b`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` c`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`It takes `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`n`}</code>{` selectors and allows you to `}<em parentName="p">{`compose`}</em>{` them, finally providing you with a function to map the selected values into the value returned from a new selector.`}</p>
    <p>{`This pattern can be emulated using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sequenceT`}</code>{` method from fp-ts and the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{` function we implemented above:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` mapped `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` selector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sequenceT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`selector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    aSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    bSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    cSelector
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` b`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` c`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p><em parentName="p">{`Remember: `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`selector`}</code>{` is an instance of our typeclass, not an actual instance of a selector`}</em>{`. `}<em parentName="p">{`Also note that in the fp-ts example, `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`a`}</code>{`, `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`b`}</code>{`, and `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`c`}</code>{`'s types are properly inferred`}</em>{`.`}</p>
    <p>{`The reselect version is less characters, but it's implemented specifically for selectors. In the fp-ts example, it was provided for us for free! We just needed to describe how two selectors are combined, and the library takes care of the rest for us.`}</p>
    <h2>{`Why go through all this trouble?`}</h2>
    <p>{`It can be hard at first to see these patterns in code you're using and writing. The only advice I can give is to learn as many examples of functors, applicatives, and monads that you can. Don't just read materials, bust out an editor, where you can quickly try out these concepts and verify what you're reading. I'm sure you'd be surprised how quickly you can gain an intuition.`}</p>
    <p>{`One of the biggest benefits that comes from recognizing these patterns is that you'll find new ways to use your types. For example, if you were only exposed to the reselect library, you might not realize that you can turn an array of selectors into a selector of array. If you had an intuition around applicatives, this becomes easier to recognize, and in turn you'll be rewarded with a bunch of reusable code.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      